!function (e) {
    "use strict";
    var i = e(window),
        t = e("dd"),
        o = e(".ecaps-page-wrapper"),
        a = e(".ecaps-sidemenu-area");
    if (i.on("load", function () {
        e("#preloader").fadeOut("1000", function () {
            e(this).remove()
        })
    })
        , e.fn.slimscroll && e(".toDolist").slimscroll({
            height: "330px",
            size: "2px",
            position: "right",
            color: "#8c8c8c",
            alwaysVisible: !1,
            distance: "0px",
            railVisible: !1,
            wheelStep: 15
        }), e.fn.slimscroll && e("#messageBox, #notificationsBox").slimscroll({
            height: "260px",
            size: "2px",
            position: "right",
            color: "#8c8c8c",
            alwaysVisible: !1,
            distance: "0px",
            railVisible: !1,
            wheelStep: 15
        }),
        e.fn.slimscroll && e(".widgetbox").slimscroll({
            height: "400px",
            size: "2px",
            position: "right",
            color: "#8c8c8c",
            alwaysVisible: !1,
            distance: "0px",
            railVisible: !1,
            wheelStep: 15
        }),
        e.fn.slimscroll && e("#chooseLayout, #quickSettingPanel").slimscroll({
            height: "100%",
            size: "2px",
            position: "right",
            color: "#8c8c8c",
            alwaysVisible: !1,
            distance: "0px",
            railVisible: !1,
            wheelStep: 15
        }),
        e("#menuCollasped").on("click", function () {
            console.log(333333);
            o.toggleClass("menu-collasped-active")
        }),
        e("#mobileMenuOpen").on("click", function () {
            console.log(1111111111111333);

            o.toggleClass("mobile-menu-active")
        }),
        e("#rightSideTrigger").on("click", function () {
            e(".right-side-content").toggleClass("active")
        }),

        e("#settingTrigger").on("click", function () {
            e(".choose-layout-area").toggleClass("active")
        }),
        e("#settingCloseIcon").on("click", function () {
            e(".choose-layout-area").removeClass("active")
        }),
        e("#quicksettingTrigger").on("click", function () {
            e(".quick-settings-panel").toggleClass("active")
        }), e("#quicksettingCloseIcon").on("click", function () {
            e(".quick-settings-panel").removeClass("active")
        }),
        e.fn.owlCarousel) {
        var l = e(".welcome_slides");
        l.owlCarousel({ items: 1, loop: !0, autoplay: !0, smartSpeed: 1500, nav: !0, navText: ["<i class='pe-7s-angle-left'</i>", "<i class='pe-7s-angle-right'</i>"], dots: !0, animateIn: "fadeIn", animateOut: "fadeOut" }), l.on("translate.owl.carousel", function () { e("[data-animation]").each(function () { var i = e(this).data("animation"); e(this).removeClass("animated " + i).css("opacity", "0") }) }), e("[data-delay]").each(function () { var i = e(this).data("delay"); e(this).css("animation-delay", i) }), e("[data-duration]").each(function () { var i = e(this).data("duration"); e(this).css("animation-duration", i) }), l.on("translated.owl.carousel", function () { l.find(".owl-item.active").find("[data-animation]").each(function () { var i = e(this).data("animation"); e(this).addClass("animated " + i).css("opacity", "1") }) }), e(".widget-slides").owlCarousel({ items: 1, loop: !0, autoplay: !0, smartSpeed: 750, margin: 0 }), e(".widget-slider-2").owlCarousel({ items: 1, loop: !0, autoplay: !0, smartSpeed: 700, margin: 30, center: !0, dots: !1 }), e(".widget-slider-3").owlCarousel({ items: 1, loop: !0, autoplay: !0, smartSpeed: 600, margin: 30, center: !0, nav: !1, dots: !1 })
    }

    e.fn.popover && e('[data-toggle="popover"]').popover(),
        e.fn.footable && e(".footable").footable(),
        e.fn.niceSelect && e("select").niceSelect(),
        e.fn.dropdown && e("dropdown-toggle").dropdown(),
        e.fn.jarallax && e(".jarallax").jarallax({ speed: .2 }),
        e.fn.counterUp && e(".counter").counterUp({ delay: 15, time: 2e3 }),
        i.width() > 767, t.filter(":nth-child(n+3)").hide(),
        e("dl").on("click", "dt", function () {
            e(this).next().slideDown(500).siblings("dd").slideUp(500)
        }),
        e('a[href="#"]').on("click", function (e) {
            e.preventDefault()
        })
}(jQuery);